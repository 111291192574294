<template>
    <v-container fluid>
        <v-row>
            <v-col
                cols="12"
                md="8"
            >
                <v-card
                    tile
                    elevation="2"
                > 
                    <v-card-title class="py-1">
                        {{ $t('registrations') }}
                    </v-card-title>

                    <v-data-table
                        :headers="headers"
                        :items="customerRegistrationRowsList"
                        :items-per-page="10"
                        class="elevation-2"
                        :sort-by="'utcRegistrationDate'"
                        sort-desc
                        :footer-props="{
                            itemsPerPageOptions: [10,20,50, -1],
                            'items-per-page-text': $t('entranceList-nrOfItems')
                        }"
                    >
                        <template v-slot:item.registrationNumber="{ item }">
                            <plate
                                :registration-number="item.registrationNumber"
                                :registration-type="item.registrationType"
                            />
                        </template>
                        <template v-slot:item.utcRegistrationDate="{ item }">
                            {{ item.utcRegistrationDate | luxon({ input: "formatutc", output: "formatlocal" }) }}
                        </template>
                        <template v-slot:item.orderItemPriceInMinorUnit="{ item }">
                            {{ globalConvertMinorCurrencyToLocal(item.orderItemPriceInMinorUnit) }}
                        </template>
                        <template v-slot:item.orderStatus="{ item }">
                            {{ $t('paymentStatus-'+item.orderStatus) }}
                        </template>
                        <template v-slot:item.registrationHasOrder="{ item }">
                            <v-btn
                                v-if="item.registrationHasOrder"
                                text
                                @click="getOrderPdf(item.id)"
                            >
                                <v-icon left>
                                    mdi-file-pdf
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    components: {
        Plate: () => import('@/views/components/Plate.vue')

    },
    data() {
        return {
            headers: [
                {
                    text: this.$t('imagetype-3'),
                    sortable: true,
                    value: 'registrationNumber',
                },
                {
                    text: this.$t('registrations-time'),
                    sortable: true,
                    value: 'utcRegistrationDate',
                },              
                {
                    text: this.$t('registrations-location'),
                    sortable: true,
                    value: 'location.name',
                },
                {
                    text: this.$t('order-invoice'),
                    sortable: false,
                    value: 'registrationHasOrder',
                }
            ],
            customerRegistrationRowsList: []
        }
    },        
    computed: {        
        ...mapGetters({
            getEmailConfirmed: 'getEmailConfirmed',
            getSelectedCvrNumber: 'getSelectedCvrNumber',
            getSelectedPNumber: 'getSelectedPNumber'
        })
    },
    watch: {
    }, 
    created() {
        this.FetchCustomerRegistrationRowsList({pNumber: this.getSelectedPNumber})
            .then(result =>{
                this.customerRegistrationRowsList = result.registrations
            })
    },
    methods: {
        getOrderPdf(registrationId) {
            this.FetchOrderPdf({pNumber: this.getSelectedPNumber, registrationId: registrationId})
        },
        ...mapActions({
            FetchCustomerRegistrationRowsList: 'FetchCustomerRegistrationRowsList',
            FetchOrderPdf: 'FetchOrderPdf'
        })      
    }
}
</script>